/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import React from 'react';
import Image from 'next/image';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';

const CustomImage = ({
    src, width = 500, height = 500, className = '', alt = 'Image', quality = 100, lazy = false, optimize = true, sizes = '',
}) => {
    const onError = (event) => {
        event.target.classList.add('has-error');
    };
    const isProductImage = !!(src.includes('catalog/product') || src.includes('images/product'));

    return (
        <>
            <Image
                src={`${getThumborUrl()}/unsafe/${width}x${height}/filters:format(webp)/${src}`}
                key={src}
                width={width}
                height={height}
                alt={alt}
                loading={lazy ? 'lazy' : 'eager'}
                unoptimized={!optimize}
                onError={onError}
                quality={quality}
                className={`img-bg-load ${className}`}
                sizes={sizes}
                onContextMenu={(e) => {
                    if (isProductImage) {
                        e.preventDefault();
                    }
                }}
            />
            <style jsx global>
                {`
                    img.has-error {
                        // fallback to placeholder image on error
                        content: url(/assets/img/placeholder.png);
                    }
                    .img-bg-load {
                        background: #f8f8f8;
                    }
                `}
            </style>
        </>
    );
};

export default CustomImage;
